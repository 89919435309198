<template>
  <div class="menu">
    <div class="menu-warp">
      <h3>
        当前数据列表
        <button class="refresh-btn" @click="listAllFiles">刷新</button>
      </h3>
      <ul class="menu-left">
        <li v-for="(file, i) in files" :key="i">
          <span
            class="menu-item"
            :class="{ 'menu-selected': file.selected }"
            @click="selectFile(file)"
            >{{ file.path[file.path.length - 1] }}</span
          >
        </li>
      </ul>
    </div>
    <RouteMap :routeData="selectedRoute"></RouteMap>
  </div>
</template>

<script>
import RouteMap from "./RouteMap.vue";
import api from "../api/index.js";
export default {
  name: "Menu",
  props: {},
  components: { RouteMap },
  data() {
    return {
      selectedRoute: {},
      files: [],
    };
  },
  mounted() {
    this.listAllFiles();
  },
  methods: {
    async listAllFiles() {
      this.selectedRoute = {};
      this.files = [];
      let res = await api.getLogs();
      this.files = this.adapterListIn(res.list);
    },
    async selectFile(file) {
      this.files.forEach((f) => {
        f.selected = false;
      });
      file.selected = true;
      let res = await api.getLogDetail(file.path);
      this.selectedRoute = res.data;
    },
    adapterListIn(list) {
      let res = [];
      list.forEach((f) => {
        res.push({
          path: f,
          selected: false,
        });
      });
      return res;
    },
  },
};
</script>

<style scoped>
.menu {
  display: block;
}
.menu-warp {
  width: 15%;
  float:left;
}
.menu-item {
  color: #2c3e50;
  line-height: 200%;
  padding: 0 10px;
  display: block;
}
.menu-item:hover {
  cursor: pointer;
  color: #fff;
  background: #2c3e50;
}
.menu-selected {
  color: #fff;
  background: #2c3e50;
}
.menu-left {
  max-height: 400px;
  overflow: auto;
}
</style>
