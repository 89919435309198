<template>
  <div class="route">
    <RouteList></RouteList>
  </div>
</template>

<script>
import RouteList from "../components/RouteList.vue";
export default {
  name: "Route",
  components: { RouteList },
};
</script>


<style scoped>
</style>