<template>
  <div>
    <div id="map"></div>
    <div
      v-if="
        routeData.route &&
        routeData.route.paths &&
        routeData.route.paths.length > 0
      "
    >
      距离：{{ routeData.route.paths[this.pathIndex].distance / 1000 }} 公里 |
      时间：{{
        (routeData.route.paths[this.pathIndex].duration / 3600).toFixed(2)
      }}
      小时 | 均速：
      {{
        (
          (routeData.route.paths[this.pathIndex].distance /
            1000 /
            routeData.route.paths[this.pathIndex].duration) *
          3600
        ).toFixed(2)
      }}
      公里/小时 | 高速距离：{{
        routeData.route.paths[this.pathIndex].toll_distance / 1000
      }}
      公里 | 预计收费：{{ routeData.route.paths[this.pathIndex].tolls }} 元 |
      红绿灯：{{ routeData.route.paths[this.pathIndex].traffic_lights }} 个 |
      可用规划数：{{ routeData.count }} 条 | 当前规划：第
      {{ this.pathIndex + 1 }} 条 |
      <button
        v-for="(p, i) in routeData.route.paths"
        :key="i"
        @click="selectPath(i)"
      >
        第{{ i + 1 }}条
      </button>
    </div>
  </div>
</template>

<script>
// import api from "../api/index.js";
import amap from "@amap/amap-jsapi-loader";

let POLYLINE = [];
let AMAP = null;

export default {
  name: "RouteMap",
  props: {
    routeData: { count: 0, route: {} },
  },
  watch: {
    routeData(data) {
      if (!data || !data.route) {
        this.resetMap();
        return;
      }
      console.log(data);
      this.pathIndex = 0;
      this.refreshMap(data.route);
    },
  },
  data() {
    return {
      map: null,
      pathIndex: 0,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    selectPath(i) {
      this.pathIndex = i;
      this.refreshMap(this.routeData.route);
    },
    resetMap() {
      if (this.map) {
        this.map.remove(POLYLINE);
        POLYLINE = [];
      }
    },
    refreshMap(route) {
      let getLineColor = (status) => {
        let lineColorMap = {
          畅通: "green", //#2c3e50
          缓行: "yellow",
          拥堵: "red",
          严重拥堵: "black",
          未知: "blue",
        };
        if (!lineColorMap[status]) {
          console.log(status + " not found color");
          return "#000";
        } else {
          return lineColorMap[status];
        }
      };
      this.resetMap();
      let steps = route.paths[this.pathIndex].steps;
      steps.forEach((step) => {
        step.tmcs.forEach((tmc) => {
          let path = tmc.polyline.split(";");
          let line = [];
          path.forEach((p) => {
            let latlng = p.split(",");
            line.push(new AMAP.LngLat(latlng[0], latlng[1]));
          });
          var polyline = new AMAP.Polyline({
            path: line,
            strokeWeight: 8,
            strokeColor: getLineColor(tmc.status),
            strokeOpacity: 0.7,
            strokeStyle: "solid",
            lineJoin: "round",
          });
          POLYLINE.push(polyline);
        });
      });
      POLYLINE.forEach((p) => {
        this.map.add(p);
      });
      this.map.setFitView();
    },
    initMap() {
      amap
        .load({
          key: "235b06faa88fdb08c1a3bbbcf2e472b1",
          version: "2.0",
          plugins: ["AMap.Scale"],
        })
        .then((AMap) => {
          AMAP = AMap;
          // let roadNet = new AMAP.TileLayer.RoadNet();
          // let traffic = new AMAP.TileLayer.Traffic({
          //   autoRefresh: false,
          // });
          var layer = new AMAP.createDefaultLayer({
            zooms: [3, 20], 
            visible: true, 
            opacity: 1, 
            zIndex: 0, 
          });
          this.map = new AMAP.Map("map", {
            zoom: 5,
            center: [105.602725, 37.076636],
            layers: [layer],
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
#map {
  padding: 0px;
  margin: 0px;
  width: 85%;
  height: 400px;
}
</style>
